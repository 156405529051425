import { graphql, PageProps } from 'gatsby';
import tw from 'twin.macro';
import { css } from '@emotion/react';
import BaseLayout from '../layouts/base';
import { GetLayananNew4Query } from '../graphql-types';

import Products, { Product, SubtitleProduct } from '../components/products4';
import Partner from '../components/partner';
import Faq, { FaqContent } from '../components/faq';
import HowTo from '../components/howto-en';

import Jumbotron from '../components/jumbotron';
import { Helmet } from 'react-helmet';

import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { BtnPrimary } from '../components/scaffolds';
import { ReactComponent as IconWhatsapp } from '../assets/Union.svg';

import Testimonial from '../components/testimonials-eng';
import Article from '../components/article';

function replaceNbsps(children) {
  const str = ReactDOMServer.renderToString(children as React.ReactElement);
  const re = new RegExp(String.fromCharCode(160), 'g');
  return str.replace(re, ' ');
}

const packageOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) => {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: replaceNbsps(children),
          }}
        ></div>
      );
    },
  },
  renderMark: {
    [MARKS.BOLD]: children => {
      return <b tw="text-yellow-bright dark:text-orange">{children}</b>;
    },
  },
};

const howToOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) => {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: replaceNbsps(children),
          }}
        ></div>
      );
    },
  },
  renderMark: {
    [MARKS.BOLD]: children => {
      return <b tw="text-blue-lapis dark:text-yellow-bright">{children}</b>;
    },
  },
};

const faqOptions = {
  renderMark: {
    [MARKS.BOLD]: children => {
      return <b tw="text-orange">{children}</b>;
    },
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) => {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: replaceNbsps(children),
          }}
        ></div>
      );
    },
  },
};

const relatedArticlesOptions = {
  renderMark: {
    [MARKS.BOLD]: children => {
      return <b tw="text-blue-lapis dark:text-yellow-bright">{children}</b>;
    },
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) => {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: replaceNbsps(children),
          }}
        ></div>
      );
    },
  },
};

const LayananNew4: React.FC<PageProps<GetLayananNew4Query>> = ({ data, location }) => {
  const pageData = data?.allContentfulLayanan.edges[0]?.node;
  const jumbotron = pageData?.jumbotronImage?.fluid;
  const banners = pageData?.banners;
  const bannersDetail = pageData?.bannersDetail;
  const logo = data?.logo?.childrenImageSharp[0]?.fluid;
  const partners = data?.allContentfulPartners?.edges[0]?.node?.partner;
  const contactUs = pageData?.contactUs;
  const partnerTitle = data?.allContentfulPartners?.edges[0]?.node?.optionalTitle;
  let hashtag = '';
  if (typeof window !== 'undefined') {
    hashtag = window.location.hash;
  }

  // SEO data
  const siteMeta = data?.site?.siteMetadata;
  const canonical = `${siteMeta.url}${location.pathname}`;

  const products: Product[] = pageData?.productContent?.map(p => {
    return {
      ...(p ?? {}),
      ribbon: p.ribbonText,
      estimation: <div dangerouslySetInnerHTML={{ __html: p.estimation }}></div>,
      subtitle: p.subtitle as SubtitleProduct,
      specialPrice: p.specialPrice && p.specialPrice !== '0' ? p.specialPrice : null,
    };
  });

  const faqs: FaqContent[] = pageData?.faqsContent;

  const articleContents = pageData?.relatedArticle.map(a => {
    return {
      title: a.title,
      detail: documentToReactComponents(JSON.parse(a.shortDescription?.raw)),
      date: a.date,
      info: a.info,
      img: a.img.fluid,
      link: a.uid,
    };
  });

  const codeOptions = {
    renderMark: {
      [MARKS.CODE]: code => {
        let html = code.replace(new RegExp(' ', 'g'), ' ');
        return <div dangerouslySetInnerHTML={{ __html: html }}></div>;
      },
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_, children) => {
        return <React.Fragment>{children}</React.Fragment>;
      },
    },
  };

  const seo = pageData?.seo;

  const whatsappLink = '/hubungi-kami-via-whatsapp';

  let product = [
    ...pageData?.productContent?.map(p => {
      return {
        '@type': 'Product',
        name: p.title,
        description: p.estimation,
        offers: {
          '@type': 'Offer',
          url: `https://www.easybiz.id/layanan/${pageData?.uid}/`,
          priceCurrency: 'IDR',
          price: p.price,
        },
      };
    }),
  ];

  const jsonLd = {
    '@context': 'https://schema.org',
    '@graph': [...product],
  };

  return (
    <BaseLayout
      logo={logo}
      pathname={location.pathname}
      whatsappLink={whatsappLink}
      hideHeadbump={true}
      lang='en'
    >
      <Helmet>
        <title>{seo?.title || `${pageData?.title} - Easybiz`}</title>
        <meta
          name="description"
          content={seo?.description?.description || pageData?.jumbotron?.detail}
        />
        <meta name="keywords" content={seo?.keyword} />
        <meta
          name="robots"
          content={
            seo?.robots ||
            'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'
          }
        />
        <link rel="canonical" href={canonical} />
        <meta name="author" content={seo?.author} />
        <meta name="publisher" content={seo?.publisher} />
        <meta property="og:locale" content={seo?.oglocale || 'en_US'} />
        <meta property="og:type" content={seo?.ogtype || 'website'} />
        <meta property="og:title" content={seo?.title || `${pageData?.title} - Easybiz`} />
        <meta
          property="og:description"
          content={seo?.description?.description || pageData?.jumbotron?.detail}
        />
        <meta property="og:url" content={`https://www.easybiz.id/layanan/${pageData?.uid}/`} />
        <meta property="og:image" content={pageData?.jumbotronImage?.file?.url} />
        <meta property="og:site_name" content="Easybiz" />
        <meta name="twitter:title" content={seo?.title || `${pageData?.title} - Easybiz`} />
        <meta
          name="twitter:description"
          content={seo?.description?.description || pageData?.jumbotron?.detail}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content={`https://www.easybiz.id/layanan/${pageData?.uid}/`} />
        <meta name="twitter:image" content={pageData?.jumbotronImage?.file?.url} />
        <meta name="twitter:creator" content={seo?.author} />
        <script type="application/ld+json">{JSON.stringify(jsonLd, undefined, 4)}</script>
      </Helmet>
      <Jumbotron
        title={pageData?.title}
        subtitle={pageData?.jumbotron?.title}
        detail={pageData?.jumbotron?.detail}
        banners={banners}
        bannersDetail={bannersDetail}
        hashtag={hashtag}
        wide={true}
        img={jumbotron}
        curved={false}
        jumbotronButtonText={'Contact Us'}
        offerToText={'Learn More'}
        productLink={pageData?.jumbotron?.productLink}
      ></Jumbotron>
      <section tw="bg-white dark:bg-black-300 pb-6">
        <div tw="container">
          <div
            tw="lg:text-center mx-8 lg:mx-32 lg:text-center py-12 lg:pb-28"
            css={css`
              .floating-button {
                ${tw`bg-green rounded-full hidden lg:flex fixed items-center text-center`}
                height: 72px;
                width: 72px;
                right: 1rem;
                bottom: 1rem;
                z-index: 9999;
                a {
                  ${tw`w-full`}
                }
              }
              .content-wrapper {
                .btn-primary {
                  ${tw`text-center bg-yellow-bright hover:bg-khaki active:bg-golden border-0 text-h4 text-blue-marine font-gilroy font-extrabold py-3 px-6 rounded-full cursor-pointer no-underline inline-block`}
                }
                h2 {
                  ${tw`text-black-100 dark:text-white mb-8`}
                }
                .text-left {
                  ${tw`text-left`}
                }
                .wrapper {
                  ${tw`flex flex-col lg:flex-row items-center mb-4 lg:mb-0`}
                  &.flip {
                    ${tw`flex-col-reverse lg:flex-row`}
                  }
                  img {
                    height: 306px;
                    width: 306px;
                    max-width: none;
                  }
                  .title {
                    ${tw`text-black-100 dark:text-white pb-4 m-0 text-h3 lg:text-h15 text-left`}
                  }
                  .description {
                    ${tw`text-b1 text-black-100 dark:text-black-700 text-left`}
                  }
                  .left-content {
                    ${tw`bg-transparent w-full lg:mr-20 text-center lg:text-left`}
                  }
                  .right-content {
                    ${tw`bg-transparent text-center lg:text-left`}
                  }
                }
              }
            `}
          >
            <div className="floating-button">
              <a className="gtm-call-button" href="/hubungi-kami-via-whatsapp" target="__blank">
                <IconWhatsapp></IconWhatsapp>
              </a>
            </div>
            {documentToReactComponents(
              JSON.parse(pageData?.customSection1?.raw ?? '{}'),
              codeOptions,
            )}
          </div>
        </div>
      </section>
      {pageData?.partner ? (
        <Partner
          partners={partners}
          dynamic={true}
          title={
            <div tw="text-h4 lg:text-h3 text-black-300 text-center py-6 m-0 font-gilroy">
              {partnerTitle}
            </div>
          }
        ></Partner>
      ) : (
        <React.Fragment></React.Fragment>
      )}
      {pageData?.step ? (
        <HowTo
          title={documentToReactComponents(JSON.parse(pageData?.stepTitle?.raw), howToOptions)}
          subtitle={documentToReactComponents(
            JSON.parse(pageData?.stepSubtitle?.raw),
            howToOptions,
          )}
          buttonClass="gtm-step-pendirian-pt"
          howToLink={whatsappLink}
          contents={pageData?.stepContent}
        ></HowTo>
      ) : (
        <React.Fragment></React.Fragment>
      )}
      <Products
        title={documentToReactComponents(
          JSON.parse(pageData?.productTitle?.raw ?? '{}'),
          packageOptions,
        )}
        products={products}
        notes={pageData?.productNotes}
        contactUs={contactUs}
      ></Products>
      {pageData?.benefit?.length > 1 ? (
        <Testimonial
          title={pageData?.benefitTitle}
          testimonials={pageData?.benefit}
          videoUrl={pageData?.benefit[0]?.icon || ''}
        ></Testimonial>
      ) : (
        <React.Fragment></React.Fragment>
      )}
      <Faq
        title={documentToReactComponents(JSON.parse(pageData?.faqsTitle?.raw), faqOptions)}
        faqs={faqs}
        waLink={whatsappLink}
      ></Faq>
      <Article contents={articleContents} title={documentToReactComponents(JSON.parse(pageData?.relatedArticleTitle?.raw), relatedArticlesOptions)}></Article>
      <section tw="bg-white dark:bg-black-300 pb-6">
        <div tw="container">
          <div tw="text-center">
            <p tw="text-b1 text-center mb-4">Have more questions?</p>
            <BtnPrimary
              className="gtm-call-button"
              href={'/hubungi-kami-via-whatsapp'}
              target="__blank"
            >
              Contact Us
            </BtnPrimary>
          </div>
        </div>
      </section>
    </BaseLayout>
  );
};

export default LayananNew4;

export const query = graphql`
  query getLayananNew4($uid: String!, $regexuid: String!) {
    allContentfulPartners {
      edges {
        node {
          optionalTitle
          partner {
            description
            title
            fluid(maxHeight: 486) {
              srcSet
              ...ContentfulImageFluidFields
            }
          }
        }
      }
    }
    allContentfulLayanan(filter: { uid: { eq: $uid } }) {
      nodes {
        uid
      }
      edges {
        node {
          seo {
            title
            description {
              description
            }
            keyword
            language
            robots
            author
            publisher
            updatedAt
            oglocale
            ogtype
          }
          uid
          title
          jumbotron {
            title
            detail
            productLink
            buttonText
          }
          jumbotronImage {
            file {
              url
              fileName
            }
            fluid(maxHeight: 1000) {
              ...ContentfulImageFluidFields
            }
          }
          banners {
            description
            title
            fluid(maxHeight: 1000) {
              srcSet
              ...ContentfulImageFluidFields
            }
          }
          bannersDetail {
            title
            description
            hash
            active
            buttonText
            buttonHref
          }
          partner
          productTitle {
            raw
          }
          productDescription {
            raw
          }
          productBenefit {
            id
            title
            detail
          }
          productContent {
            ribbonText
            ribbonStyle
            color
            darkColor
            price
            link
            linkText
            linkClass
            id
            estimation
            title
            specialPrice
            startFrom
            promoEnd
            tAC
            subtitle {
              items {
                list
                text
              }
              text
            }
          }
          productNotes
          step
          stepTitle {
            raw
          }
          stepSubtitle {
            raw
          }
          stepContent {
            summary
            title
            detail
          }
          faqsContent {
            id
            title
            detail {
              text
              items {
                text
                items {
                  text
                }
              }
            }
          }
          virtualOffice
          virtualOfficeDescription {
            raw
          }
          faqsTitle {
            raw
          }
          faqsSubtitle {
            raw
          }
          faqsContent {
            id
            title
            detail {
              text
              items {
                text
                items {
                  text
                }
              }
            }
          }
          relatedArticleTitle {
            raw
          }
          relatedArticle {
            uid
            title
            category
            date
            info
            shortDescription {
              raw
            }
            img {
              file {
                fileName
                url
              }
              fluid(maxHeight: 280, maxWidth: 500) {
                ...ContentfulImageFluidFields
              }
            }
          }
          sections {
            raw
          }
          customSection1 {
            raw
          }
          customSection2 {
            raw
          }
          benefitTitle
          benefit {
            icon
            title
            items
          }
          contactUs {
            urlText
            form {
              contentfulid
              label
              type
              placeholder
              column
              required
            }
          }
        }
      }
    }
    assets: allContentfulAsset(filter: { title: { regex: $regexuid } }) {
      nodes {
        title
        file {
          url
          fileName
        }
      }
    }
    logo: file(relativePath: { eq: "images/logo.png" }) {
      childrenImageSharp {
        fluid(maxWidth: 130) {
          ...FileImageSharpFluid
        }
      }
    }
    imgProduct: file(relativePath: { eq: "images/product-img.png" }) {
      childrenImageSharp {
        fluid(maxWidth: 300) {
          ...FileImageSharpFluid
        }
      }
    }
    site {
      siteMetadata {
        ...SiteMetadataFields
      }
    }
  }
`;
